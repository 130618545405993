import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {ApiResponse} from "@moodeon-commons/model/api-response";
import {environment} from "../../environments/environment";
import {CartResponse, CartServiceDetailsUpdateRequest} from "@app/model/cart";
import {CartItemResponse} from "@app/model/cart-item";

@Injectable({
    providedIn: 'root'
})
export class CartService {
    baseUrl: string = environment.apiBaseUrl;

    constructor(private _httpClient: HttpClient) {
    }

    addItemToCart(cartItemRequest: any): Observable<ApiResponse<any>> {
        return this._httpClient.post<ApiResponse<any>>(this.baseUrl + '/cart/item', cartItemRequest);
    }

    clearCart(cartId: number): Observable<ApiResponse<any>> {
        return this._httpClient.post<ApiResponse<any>>(this.concatUrl(this.baseUrl, 'cart', cartId, 'clear'), null);
    }

    getCartCount(sessionId: string, customerId?: number): Observable<ApiResponse<any>> {
        let params = new HttpParams();
        if (sessionId) {
            params = params.set('sessionId', sessionId);
        }

        if (customerId) {
            params = params.set('customerId', customerId);
        }

        return this._httpClient.get<ApiResponse<any>>(this.baseUrl + '/cart/count', {params: params});
    }

    getCartItemById(cartItemId: number): Observable<ApiResponse<CartItemResponse>> {
        return this._httpClient.get<ApiResponse<CartItemResponse>>(this.concatUrl(this.baseUrl, 'cart/item', cartItemId));
    }

    getCart(sessionId: string, customerId?: number): Observable<ApiResponse<CartResponse>> {
        let params = new HttpParams();
        if (sessionId) {
            params = params.set('sessionId', sessionId);
        }

        if (customerId) {
            params = params.set('customerId', customerId);
        }

        return this._httpClient.get<ApiResponse<CartResponse>>(this.baseUrl + '/cart', {params: params});
    }

    deleteCartItem(cartItemId: number): Observable<ApiResponse<CartResponse>> {
        return this._httpClient.delete<ApiResponse<CartResponse>>(this.concatUrl(this.baseUrl, 'cart', 'item', cartItemId));
    }

    concatUrl(...paths: any[]): string {
        return paths.join('/');
    }

    updateCartItem(cartItemId: number, cartItemRequest: any) {
        return this._httpClient.put<ApiResponse<any>>(this.concatUrl(this.baseUrl, 'cart/item', cartItemId), cartItemRequest);
    }

    patchInitialData(cartId: number, initialData: string) {
        return this._httpClient.patch<ApiResponse<CartResponse>>(this.concatUrl(this.baseUrl, 'cart', cartId, 'initial-page'), initialData);
    }

    updateServiceDetails(cartId: number, req: CartServiceDetailsUpdateRequest) {
        return this._httpClient.put<ApiResponse<any>>(this.concatUrl(this.baseUrl, 'cart', cartId, 'service-details'), req);
    }

    updateCustomerDetails(cartId: number, req: any) {
        return this._httpClient.put<ApiResponse<any>>(this.concatUrl(this.baseUrl, 'cart', cartId, 'customer-details'), req);
    }

    convertToOrder(cartId: number) {
        return this._httpClient.post<ApiResponse<any>>(this.concatUrl(this.baseUrl, 'cart', cartId, 'convert-to-order'), null);
    }
}
