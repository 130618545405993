import {NgFor, NgIf, NgTemplateOutlet, UpperCasePipe} from '@angular/common';
import {ChangeDetectorRef, Component, OnDestroy, ViewEncapsulation} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatMenuModule} from '@angular/material/menu';
import {TranslocoModule} from '@ngneat/transloco';
import {Subject, takeUntil} from 'rxjs';
import {CommonEvents} from "@moodeon-commons/util/common-events";
import {BranchService} from "@app/service/branch.service";
import {ApiUtils} from "@moodeon-commons/util/api-utils";
import {BranchListItemResponse} from "@app/model/branch";
import {LocalStorageHandler} from "@app/local-storage/local-storage-handler";
import {CustomerService} from "@app/service/customer.service";

@Component({
    selector: 'branch-filter',
    templateUrl: './branch-filter.component.html',
    encapsulation: ViewEncapsulation.None,
    exportAs: 'branches',
    standalone: true,
    imports: [MatButtonModule, MatMenuModule, NgTemplateOutlet, NgFor, UpperCasePipe, NgIf, TranslocoModule],
})
export class BranchFilterComponent implements OnDestroy {
    branches: BranchListItemResponse[];
    currentBranch: BranchListItemResponse;
    filterVisible = false;

    private _unsubscribeAll: Subject<any> = new Subject<any>();

    constructor(private branchService: BranchService, private cdr: ChangeDetectorRef, customerService: CustomerService) {
        this.initUi();
    }

    initUi() {
        this.currentBranch = LocalStorageHandler.CURRENT_BRANCH.get();

        this.branchService.getList({pageSize: ApiUtils.DEFAULT_LIST_PAGE_SIZE})
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(value => {
                this.branches = value.payload;
                this.filterVisible = true;
            });
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    setCurrentBranch(branch: BranchListItemResponse): void {
        LocalStorageHandler.CURRENT_BRANCH.set(branch);

        if (this.currentBranch?.id == branch?.id) {
            return;
        }

        this.currentBranch = branch;
        CommonEvents.dispatchEvent(CommonEvents.CURRENT_BRANCH_CHANGED, branch);
    }

    trackByFn(index: number, item: any): any {
        return item.id || index;
    }
}
