// import {CurrentUser} from "@app/model/admin-user";

export class SpecificLocalStorageHandler<T> {
    constructor(private key: string) {
    }

    public get(defaultVal?: T): T {
        const jsonStr = localStorage.getItem(this.key);
        return jsonStr?.length > 0 ? JSON.parse(jsonStr) as T : defaultVal;
    }

    public set(value: T): void {
        localStorage.setItem(this.key, value != null ? JSON.stringify(value) : null);
    }

    public remove(): void {
        localStorage.removeItem(this.key);
    }
}
