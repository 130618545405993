// import {CurrentUser} from "@app/model/admin-user";
import {SpecificLocalStorageHandler} from "@app/local-storage/specific-local-storage-handler";
import {BranchListItemResponse} from "@app/model/branch";
import {Currency} from "@moodeon-commons/model/currency";
import {Language} from "@moodeon-commons/model/language";
import {CurrentUser} from "@app/model/customer";

export class LocalStorageHandler {
    public static LANGUAGES = new SpecificLocalStorageHandler<Language[]>('langs');
    public static CURRENT_LANGUAGE = new SpecificLocalStorageHandler<Language>('langObj');
    public static CURRENT_CURRENCY = new SpecificLocalStorageHandler<Currency>('currencyObj');
    public static CURRENT_BRANCH = new SpecificLocalStorageHandler<BranchListItemResponse>('currentBranch');
    public static CURRENT_USER = new SpecificLocalStorageHandler<CurrentUser>('currentUser');
    public static CART_SESSION_ID = new SpecificLocalStorageHandler<string>('cartSessionId');

    public static get<T>(key: string, defaultVal?: T): T {
        const jsonStr = localStorage.getItem(key);
        return jsonStr?.length > 0 ? JSON.parse(jsonStr) as T : defaultVal;
    }

    public static set<T>(key: string, value: T): void {
        localStorage.setItem(key, value != null ? JSON.stringify(value) : null);
    }

    public static remove(key: string): void {
        localStorage.removeItem(key);
    }
}
