import {NgIf} from '@angular/common';
import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {RouterOutlet} from '@angular/router';
import {FuseFullscreenComponent} from '@fuse/components/fullscreen';
import {FuseLoadingBarComponent} from '@fuse/components/loading-bar';
import {
    FuseHorizontalNavigationComponent,
    FuseNavigationItem,
    FuseNavigationService,
    FuseVerticalNavigationComponent
} from '@fuse/components/navigation';
import {MediaWatcherService} from '@fuse/services/media-watcher';
import {LanguagesComponent} from 'app/layout/common/languages/languages.component';
import {MessagesComponent} from 'app/layout/common/messages/messages.component';
import {NotificationsComponent} from 'app/layout/common/notifications/notifications.component';
import {QuickChatComponent} from 'app/layout/common/quick-chat/quick-chat.component';
import {SearchComponent} from 'app/layout/common/search/search.component';
import {ShortcutsComponent} from 'app/layout/common/shortcuts/shortcuts.component';
import {UserComponent} from 'app/layout/common/user/user.component';
import {Subject, takeUntil} from 'rxjs';
import {navigation} from "../../navigation";
import {CartPanelIconComponent} from "@app/layout/common/cart-panel-icon/cart-panel-icon.component";
import {CurrenciesComponent} from "@app/layout/common/currencies/currencies.component";
import {KeycloakService} from "keycloak-angular";
import {AuthUtils} from "@app/core/auth/auth.utils";
import {FavouritesPanelIconComponent} from "@app/layout/common/favourites-panel-icon/favourites-panel-icon.component";
import {BranchFilterComponent} from "@app/layout/common/branch-filter/branch-filter.component";

@Component({
    selector: 'modern-layout',
    templateUrl: './modern.component.html',
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [FuseLoadingBarComponent, NgIf, FuseVerticalNavigationComponent, FuseHorizontalNavigationComponent, MatButtonModule,
        MatIconModule, LanguagesComponent, FuseFullscreenComponent, SearchComponent, ShortcutsComponent, MessagesComponent,
        NotificationsComponent, UserComponent, RouterOutlet, QuickChatComponent, CartPanelIconComponent, CurrenciesComponent, FavouritesPanelIconComponent, BranchFilterComponent],
})
export class ModernLayoutComponent implements OnInit, OnDestroy {
    isScreenSmall: boolean;
    finalNavigation: FuseNavigationItem[];
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    hasAuthenticated = false;

    constructor(keycloakService: KeycloakService, private _fuseMediaWatcherService: MediaWatcherService,
                private _fuseNavigationService: FuseNavigationService) {
        const token = keycloakService.getKeycloakInstance()?.token;
        if (token && !AuthUtils.isTokenExpired(token)) {
            this.hasAuthenticated = true;
            this.finalNavigation = navigation;
        } else {
            this.finalNavigation = navigation.filter(navigation => !navigation.authRequired);
        }
    }

    get currentYear(): number {
        return new Date().getFullYear();
    }

    ngOnInit(): void {
        this._fuseMediaWatcherService.onScreenSizeChange$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((screenSize) => {
                this.isScreenSmall = screenSize.factor < 2;
            });
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    toggleNavigation(name: string): void {
        const navigation = this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(name);

        if (navigation) {
            navigation.toggle();
        }
    }
}
