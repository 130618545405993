import {Component} from '@angular/core';
import {RouterOutlet} from '@angular/router';
import {LayoutComponent} from "./layout/layout.component";
import {LocalStorageHandler} from "@app/local-storage/local-storage-handler";
import {MatDialog} from "@angular/material/dialog";
import {WelcomePopUpComponent} from "@app/modules/home/welcome-pop-up/welcome-pop-up.component";
import {BaseComponent} from "@moodeon-commons/component/base-component";
import {MerchantService} from "@app/service/merchant.service";
import {takeUntil} from "rxjs";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: true,
    imports: [RouterOutlet, LayoutComponent],
})
export class AppComponent extends BaseComponent {
    constructor(private matDialog: MatDialog, private merchantService: MerchantService) {
        super();
    }

    ngOnInit() {
        this.checkForWelcomePopUp();
        this.getMerchantSettings();
    }

    showWelcomePopup() {
        const matDialogRef = this.matDialog.open(WelcomePopUpComponent, {disableClose: true});

        matDialogRef.afterClosed().subscribe(result => {
            LocalStorageHandler.RTC.set(true);
        })
    }

    private checkForWelcomePopUp() {
        // only for non-returning customer
        const returningCustomer: boolean = LocalStorageHandler.RTC.get();

        if (returningCustomer) {
            return;
        }

        setTimeout(() => this.showWelcomePopup(), 3000);
    }

    private getMerchantSettings() {
        this.merchantService.getMerchantSettings().pipe(takeUntil(this._unsubscribeAll))
            .subscribe(result => {
                LocalStorageHandler.MERCHANT_SETTINGS.set(result.payload);
            });
    }
}
