<mdo-dialog-container widthClasses="max-w-540 sm:min-w-80 md:min-w-140 lg:min-w-160">
    <mdo-dialog-header [title]="'welcomePopUp.title' | transloco: {restaurantName: restaurantName}"></mdo-dialog-header>
    <mdo-dialog-content className="overflow-y mx-6">
        <div class="flex flex-col mt-4">
            <span>{{ 'welcomePopUp.description' | transloco }}</span>

            <span class="text-md font-semibold mt-4 mb-1">{{ 'welcomePopUp.preferredStore' | transloco }}</span>
            <branch-filter [defaultMode]="false"></branch-filter>

            <span class="text-md font-semibold mt-4 mb-1">{{ 'welcomePopUp.preferredLanguage' | transloco }}</span>
            <languages [defaultMode]="false"></languages>

            <span class="text-md font-semibold mt-4 mb-1">{{ 'welcomePopUp.preferredCurrency' | transloco }}</span>
            <currencies [defaultMode]="false"></currencies>
        </div>
    </mdo-dialog-content>
    <mdo-dialog-footer (dismissButtonClicked)="dialogRef.close()" [showSubmitButton]="false"
                       dismissButtonLabel="action.ok"></mdo-dialog-footer>
</mdo-dialog-container>
