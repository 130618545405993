import {NgFor, NgIf, NgTemplateOutlet, UpperCasePipe} from '@angular/common';
import {ChangeDetectorRef, Component, Input, ViewEncapsulation} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatMenuModule} from '@angular/material/menu';
import {TranslocoModule} from '@ngneat/transloco';
import {takeUntil} from 'rxjs';
import {CommonEvents} from "@moodeon-commons/util/common-events";
import {BranchService} from "@app/service/branch.service";
import {ApiUtils} from "@moodeon-commons/util/api-utils";
import {BranchListItemResponse} from "@app/model/branch";
import {LocalStorageHandler} from "@app/local-storage/local-storage-handler";
import {ActivatedRoute, Router} from "@angular/router";
import {BaseComponent} from "@moodeon-commons/component/base-component";
import {MatRippleModule} from "@angular/material/core";

@Component({
    selector: 'branch-filter',
    templateUrl: './branch-filter.component.html',
    encapsulation: ViewEncapsulation.None,
    exportAs: 'branches',
    standalone: true,
    imports: [MatButtonModule, MatMenuModule, NgTemplateOutlet, NgFor, UpperCasePipe, NgIf, TranslocoModule, MatRippleModule],
})
export class BranchFilterComponent extends BaseComponent {
    branches: BranchListItemResponse[];
    currentBranch: BranchListItemResponse;
    filterVisible = false;

    @Input()
    defaultMode = true;

    constructor(private branchService: BranchService, private cdr: ChangeDetectorRef, private route: ActivatedRoute, private router: Router) {
        super();
    }

    ngOnInit() {
        this.initUi();
    }

    initParams() {
        this.route.queryParams.subscribe(params => {
            const preferredBranchId = Number(params.pb);
            if (isNaN(preferredBranchId)) {
                if (this.currentBranch) {
                    this.router.navigate([], {
                        queryParams: {pb: this.currentBranch.id},
                        queryParamsHandling: 'merge',
                        replaceUrl: true
                    });
                }
                return;
            }

            if (this.currentBranch == null || this.currentBranch.id != preferredBranchId) {
                this.currentBranch = this.branches.find(value => value.id == preferredBranchId);

                if (this.currentBranch) {
                    LocalStorageHandler.CURRENT_BRANCH.set(this.currentBranch);
                }
            }
        });
    }

    initUi() {
        this.currentBranch = LocalStorageHandler.CURRENT_BRANCH.get();

        this.branchService.getList({pageSize: ApiUtils.DEFAULT_LIST_PAGE_SIZE})
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(value => {
                this.branches = value.payload;
                this.filterVisible = true;
                this.initParams();
            });
    }

    setCurrentBranch(branch: BranchListItemResponse): void {
        LocalStorageHandler.CURRENT_BRANCH.set(branch);

        if (this.currentBranch?.id == branch?.id) {
            return;
        }

        this.currentBranch = branch;
        CommonEvents.dispatchEvent(CommonEvents.CURRENT_BRANCH_CHANGED, branch);
        this.router.navigate([], {
            queryParams: {pb: this.currentBranch.id},
            queryParamsHandling: 'merge',
            replaceUrl: true
        });
    }

    trackByFn(index: number, item: any): any {
        return item.id || index;
    }
}
