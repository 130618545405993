import {BaseFilter} from "@moodeon-commons/model/common";
import {BaseModel} from "@moodeon-commons/model/base-model";
import {CartItemAddOnRequest, CartItemAddOnResponse} from "@app/model/cart-item-add-on";
import {IngredientListItemResponse} from "@app/model/ingredient";

export class CartItemListItemResponse extends BaseModel {
    cart: number;
    quantity: number;
    notes: string;
    addOns: string;
    removingIngredients: string;
}

export class CartItemRequest extends BaseModel {
    cartId: number;
    itemId: number;
    quantity: number;
    notes: string;
    sessionId: string;
    addOns: CartItemAddOnRequest[];
    removingIngredientIds: number[];
}

export class CartItemResponse extends BaseModel {
    itemName: string;
    itemVariant: string;
    itemId: number;
    image: string;
    quantity: number;
    itemPrice: number;
    totalUnitPrice: number;
    totalPrice: number;
    notes: string;
    addOnsSelectable: boolean;
    addOns: CartItemAddOnResponse[];
    favourite: boolean;
    removingIngredients: IngredientListItemResponse[];
}

export class CartItemFilter extends BaseFilter {
    cartId: number;
}
