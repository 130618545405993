<!-- Button -->
<button *ngIf="defaultMode" [matMenuTriggerFor]="currencies" mat-button>
    <ng-container *ngTemplateOutlet="selector"></ng-container>
</button>

<div *ngIf="!defaultMode" [matMenuTriggerFor]="currencies" class="border cursor-pointer w-48 px-4 py-3 rounded"
     mat-ripple>
    <ng-container *ngTemplateOutlet="selector"></ng-container>
</div>

<ng-template #selector>
    <span class="flex items-center mat-ripple">
        <span class="relative w-6 rounded-sm overflow-hidden">
            <img [alt]="'Flag image for ' + activeCurrency?.flag" [src]="imgUrl" class="w-full">
        </span>
        <span class="ml-3">{{ activeCurrency?.code }}</span>
    </span>
</ng-template>

<!-- Currency menu -->
<mat-menu
    #currencies="matMenu"
    [xPosition]="'before'">
    <ng-container *ngFor="let currency of availableCurrencies; trackBy: trackByFn">
        <button
            (click)="setActiveCurrency(currency)"
            mat-menu-item>
            <span class="flex items-center">
                <span class="relative w-6 rounded-sm overflow-hidden">
                    <img
                        [alt]="'Flag image for ' + currency.flag"
                        class="w-full"
                        src="https://raw.githubusercontent.com/lipis/flag-icons/main/flags/4x3/{{currency.flag}}.svg">
                </span>
                <span class="ml-3">{{ currency.name }}</span>
            </span>
        </button>
    </ng-container>
</mat-menu>

