<ng-container *transloco="let t; read: 'branchFilter'">
    <button *ngIf="defaultMode" [matMenuTriggerFor]="branchMenu" mat-button>
        <ng-container *ngTemplateOutlet="selector"></ng-container>
    </button>

    <div *ngIf="!defaultMode" [matMenuTriggerFor]="branchMenu" class="border cursor-pointer w-48 px-4 py-3 rounded"
         mat-ripple>
        <ng-container *ngTemplateOutlet="selector"></ng-container>
    </div>

    <ng-template #selector>
        <span class="flex flex-col items-center mat-ripple">
            <span *ngIf="defaultMode" class="text-primary text-xs font-semibold mb-1">{{ t('currentBranch') }}</span>
            <span class="self-start">{{ currentBranch?.name || t('none') }}</span>
        </span>
    </ng-template>

    <mat-menu #branchMenu="matMenu" [xPosition]="'before'">
        <button (click)="setCurrentBranch(null)" mat-menu-item>
            <span class="flex items-center">
                <span class="ml-3">{{ t('none') }}</span>
            </span>
        </button>
        <ng-container *ngFor="let branch of branches; trackBy: trackByFn">
            <button (click)="setCurrentBranch(branch)" mat-menu-item>
            <span class="flex items-center">
                <span class="ml-3">{{ branch.name }}</span>
            </span>
            </button>
        </ng-container>
    </mat-menu>
</ng-container>
