<!-- Button -->
<button *ngIf="defaultMode" [matMenuTriggerFor]="languages" mat-button>
    <ng-container *ngTemplateOutlet="selector"></ng-container>
</button>

<div *ngIf="!defaultMode" [matMenuTriggerFor]="languages" class="border cursor-pointer w-48 px-4 py-3 rounded"
     mat-ripple>
    <ng-container *ngTemplateOutlet="selector"></ng-container>
</div>

<ng-template #selector>
    <span class="flex items-center mat-ripple">
        <span class="relative w-6 rounded-sm overflow-hidden">
            <img [alt]="'Flag image for ' + activeLang?.flag" [src]="imgUrl" class="w-full">
        </span>
        <span class="ml-3">{{ activeLang?.code }}</span>
    </span>
</ng-template>

<!-- Language menu -->
<mat-menu
    #languages="matMenu"
    [xPosition]="'before'">
    <ng-container *ngFor="let lang of availableLangs; trackBy: trackByFn">
        <button
            (click)="setActiveLang(lang)"
            mat-menu-item>
            <span class="flex items-center">
                <span class="relative w-6 rounded-sm overflow-hidden">
                    <img
                        [alt]="'Flag image for ' + lang.flag"
                        class="w-full"
                        src="https://raw.githubusercontent.com/lipis/flag-icons/main/flags/4x3/{{lang.flag}}.svg">
                </span>
                <span class="ml-3">{{ lang.name }}</span>
            </span>
        </button>
    </ng-container>
</mat-menu>

