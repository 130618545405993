import {Component, OnInit} from '@angular/core';
import {CommonModule, CurrencyPipe} from '@angular/common';
import {SubHeadingComponent} from "@moodeon-commons/component/sub-heading/sub-heading.component";
import {FuseCardComponent} from "../../../../@fuse/components/card";
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";
import {ButtonComponent} from "@moodeon-commons/component/button/button/button.component";
import {
    ProductCardThumbnailComponent
} from "@app/modules/item/card/product-card-thumbnail/product-card-thumbnail.component";
import {takeUntil} from "rxjs";
import {ItemListItemResponse} from "@app/model/item";
import {TranslocoModule} from "@ngneat/transloco";
import {FavouriteService} from "@app/service/favourite.service";
import {CommonEvents} from "@moodeon-commons/util/common-events";
import {BaseComponent} from "@moodeon-commons/component/base-component";
import pluralize from "pluralize";

@Component({
    selector: 'app-item-list',
    standalone: true,
    imports: [CommonModule, SubHeadingComponent, FuseCardComponent, MatIconModule, MatButtonModule, ButtonComponent, ProductCardThumbnailComponent, TranslocoModule],
    templateUrl: './favourite-items-list.component.html',
    providers: [CurrencyPipe],
    styles: []
})
export class FavouriteItemsListComponent extends BaseComponent implements OnInit {
    favourites: ItemListItemResponse[];
    favouritesCount: number;

    constructor(private favouriteService: FavouriteService) {
        super();
        window.addEventListener(CommonEvents.FAVOURITES_UPDATED, (event: CustomEvent<string>) => {
            this.loadList();
        });
    }
    protected readonly pluralize = pluralize;

    ngOnInit(): void {
        this.loadList();
    }

    loadList() {
        this.favouriteService.getList().pipe(takeUntil(this._unsubscribeAll))
            .subscribe(response => this.favourites = response.payload);

        this.favouriteService.getFavouriteCount().pipe(takeUntil(this._unsubscribeAll))
            .subscribe(response => {
                this.favouritesCount = response.payload?.count;
            });
    }
}
