import {NgFor, NgTemplateOutlet} from '@angular/common';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
    ViewEncapsulation
} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatMenuModule} from '@angular/material/menu';
import {AvailableLangs, LangDefinition, TranslocoService} from '@ngneat/transloco';
import {Subject, takeUntil} from 'rxjs';
import {Language} from "@moodeon-commons/model/language";
import {TranslationHandler} from "@moodeon-commons/handler/translation-handler";
import {CommonEvents} from "@moodeon-commons/util/common-events";
import {TranslationService} from "@app/service/translation.service";

@Component({
    selector: 'languages',
    templateUrl: './languages.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs: 'languages',
    standalone: true,
    imports: [MatButtonModule, MatMenuModule, NgTemplateOutlet, NgFor],
})
export class LanguagesComponent implements OnInit, OnDestroy {
    availableLangs: Language[];
    activeLang: Language;
    imgUrl: string

    private _unsubscribeAll: Subject<any> = new Subject<any>();

    constructor(private translationService: TranslationService, private _translocoService: TranslocoService,
                private cdr: ChangeDetectorRef) {
    }

    ngOnInit(): void {
        this.activeLang = TranslationHandler.getCurrentLanguage();
        this._translocoService.langChanges$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((activeLang) => {
                if (this.availableLangs == null) {
                    return;
                }
                this.activeLang = this.availableLangs.filter(value => value.code.toLowerCase() == activeLang.toLowerCase())[0];
                setTimeout(() => {
                    CommonEvents.dispatchEvent(CommonEvents.LANGUAGE_CHANGED, this.activeLang);
                    this.resetFlagUrl(this.activeLang?.flag);
                }, 10);
            });

        this.translationService.getLanguages()
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(value => {
                TranslationHandler.setAvailableLanguages(value.payload);
                this.availableLangs = value.payload;
                this._translocoService.setAvailableLangs(this.getTranslocoAvailableLangs(value.payload));
                this._translocoService.setActiveLang(this.activeLang?.code || TranslationHandler.getBrowserLanguage());
                if (this.activeLang == null) {
                    this.activeLang = this.availableLangs[0];
                    TranslationHandler.setCurrentLanguage(this.activeLang);
                    this.resetFlagUrl(this.activeLang?.flag);
                }

                if (TranslationHandler.getCurrentLanguage() == null) {
                    TranslationHandler.setCurrentLanguage(this.activeLang);
                }
            });

        if (this.activeLang != null) {
            this.resetFlagUrl(this.activeLang?.flag);
        }
    }

    resetFlagUrl(flag: string) {
        this.imgUrl = 'https://raw.githubusercontent.com/lipis/flag-icons/main/flags/4x3/' + flag + '.svg';
        this.cdr.detectChanges();
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    setActiveLang(lang: Language): void {
        this._translocoService.setActiveLang(lang.code);
        TranslationHandler.setCurrentLanguage(lang);
        this.resetFlagUrl(lang?.flag);
    }

    trackByFn(index: number, item: any): any {
        return item.id || index;
    }

    private getTranslocoAvailableLangs(payload: Language[]): AvailableLangs {
        return payload.map(value => {
            const lang: LangDefinition = {id: value.code, label: value.name};
            return lang;
        });
    }
}
