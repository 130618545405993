import {Component, Input} from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {ButtonComponent} from "@moodeon-commons/component/button/button/button.component";
import {FuseCardComponent} from "../../../../../@fuse/components/card";
import {RouterLink} from "@angular/router";
import {ItemListItemResponse} from "@app/model/item";
import {CurrencyConverterPipe} from "@moodeon-commons/pipe/currency-converter.pipe";
import {ImageUtils} from "@moodeon-commons/util/image-utils";
import {translate, TranslocoModule} from "@ngneat/transloco";
import {FavouriteService} from "@app/service/favourite.service";
import {takeUntil} from "rxjs";
import {BaseComponent} from "@moodeon-commons/component/base-component";
import {CommonEvents} from "@moodeon-commons/util/common-events";
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";
import {MatFormFieldModule} from "@angular/material/form-field";
import {CartService} from "@app/service/cart.service";
import {LocalStorageHandler} from "@app/local-storage/local-storage-handler";
import {CartItemRequest} from "@app/model/cart-item";
import {AlertService} from "@moodeon-commons/service/alert.service";
import {MediaWatcherService} from "../../../../../@fuse/services/media-watcher";

@Component({
    selector: 'thumb-type-product-card',
    standalone: true,
    imports: [CommonModule, ButtonComponent, FuseCardComponent, RouterLink, CurrencyConverterPipe, TranslocoModule,
        NgOptimizedImage, MatButtonModule, MatIconModule, MatFormFieldModule],
    templateUrl: './product-card-thumbnail.component.html',
    styles: []
})
export class ProductCardThumbnailComponent extends BaseComponent {
    @Input()
    item: ItemListItemResponse;

    protected readonly ImageUtils = ImageUtils;
    isSmallScreen: boolean;

    constructor(private favouriteService: FavouriteService, private cartService: CartService,
                private _alertService: AlertService, private mediaWatcherService: MediaWatcherService) {
        super();

        this.mediaWatcherService.onScreenSizeChange$.pipe(takeUntil(this._unsubscribeAll))
            .subscribe((screenSize) => {
                this.isSmallScreen = screenSize.factor < 2;
            });
    }

    toggleFavourite() {
        if (this.item.favourite) {
            this.removeFromFavourites();
        } else {
            this.addToFavourites();
        }
    }

    addToFavourites() {
        this.favouriteService.create({itemId: this.item?.id}).pipe(takeUntil(this._unsubscribeAll))
            .subscribe(response => {
                this.item.favourite = true;
                CommonEvents.dispatchEvent(CommonEvents.FAVOURITES_UPDATED, response.payload.id);
            });
    }

    removeFromFavourites() {
        this.favouriteService.delete(this.item?.id).pipe(takeUntil(this._unsubscribeAll))
            .subscribe(response => {
                this.item.favourite = false;
                CommonEvents.dispatchEvent(CommonEvents.FAVOURITES_UPDATED, this.item?.id);
            });
    }

    addToCart() {
        const cartItemRequest = new CartItemRequest();
        cartItemRequest.sessionId = LocalStorageHandler.CART_SESSION_ID.get();
        cartItemRequest.itemId = this.item.id;
        cartItemRequest.quantity = 1;
        this.cartService.addItemToCart(cartItemRequest).pipe(takeUntil(this._unsubscribeAll))
            .subscribe(response => {
                LocalStorageHandler.CART_SESSION_ID.set(response.payload.sessionId);
                CommonEvents.dispatchEvent(CommonEvents.CART_UPDATED, response.payload.id);
                this._alertService.showSuccessAlert(translate('item.itemAddedToCart'));
            })
    }
}
