import {KeycloakService} from 'keycloak-angular';
import {environment} from "../../../environments/environment";
import {TokenProps} from "@app/core/auth/token-props";
import {CustomerService} from "@app/service/customer.service";
import {LocalStorageHandler} from "@app/local-storage/local-storage-handler";

export const initKeycloak = (keycloak: KeycloakService, customerService: CustomerService): Promise<boolean> => {
    TokenProps.tenant = window.location.href.match(environment.urlRegex)[1];

    return keycloak.init({
        config: {
            url: environment.keycloak.authUrl,
            realm: 'customer-' + TokenProps.tenant,
            clientId: environment.keycloak.clientId
        },
        enableBearerInterceptor: true,
        loadUserProfileAtStartUp: false,
        initOptions: {
            onLoad: 'check-sso',
            checkLoginIframe: false,
            flow: 'implicit',
            locale: LocalStorageHandler.CURRENT_LANGUAGE.get()?.code
        },
        bearerExcludedUrls: [],
        shouldAddToken(request) {
            return true;
        }
    }).then(async authenticated => {
        if (authenticated) {
            await customerService.getCurrentUser(true, true).toPromise();
        }
        return Promise.resolve(authenticated);
    });
};
