import {DatePipe, NgClass, NgFor, NgIf, NgTemplateOutlet} from '@angular/common';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
    ViewEncapsulation
} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatTooltipModule} from '@angular/material/tooltip';
import {Router, RouterLink} from '@angular/router';
import {takeUntil} from 'rxjs';
import {CartService} from "@app/service/cart.service";
import {LocalStorageHandler} from "@app/local-storage/local-storage-handler";
import {CommonEvents} from "@moodeon-commons/util/common-events";
import {BaseComponent} from "@moodeon-commons/component/base-component";

@Component({
    selector: 'cart-panel-icon',
    templateUrl: './cart-panel-icon.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs: 'messages',
    standalone: true,
    imports: [MatButtonModule, NgIf, MatIconModule, MatTooltipModule, NgFor, NgClass, NgTemplateOutlet, RouterLink, DatePipe],
})
export class CartPanelIconComponent extends BaseComponent implements OnInit, OnDestroy {
    itemCount: number = 0;

    constructor(private _changeDetectorRef: ChangeDetectorRef, private cartService: CartService, private router: Router) {
        super();
        window.addEventListener(CommonEvents.CART_UPDATED, (event: CustomEvent<string>) => {
            this.loadCount();
        });
    }

    ngOnInit(): void {
        this.loadCount();
    }

    loadCount() {
        const sessionId = LocalStorageHandler.CART_SESSION_ID.get();

        if (!sessionId) {
            return;
        }

        this.cartService.getCartCount(sessionId).pipe(takeUntil(this._unsubscribeAll))
            .subscribe(response => {
                this.itemCount = response.payload?.count;
                this._changeDetectorRef.markForCheck();
            });
    }

    gotoCart() {
        this.router.navigateByUrl('/cart');
    }
}
